@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .category-pill {
    @apply inline-flex items-center px-3 py-2 text-xs mr-2 text-center border rounded-full focus:outline-none text-primary bg-white
  }

  .category-pill-selected {
    @apply bg-secondary border-secondary
  }

  .hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
}

.container-prices {

}

.container-prices input {
  border-radius: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  width: 170px;
  font-size: 13px;
  max-width: 170px !important;
}

.container-prices select {
  width: 170px;
  font-size: 13px;
  max-width: 170px !important;
}

.container-multiples-prices {
  display: flex;
}

.container-multiple-price {
  background-color: #fff;
  border: 1px solid #c0c0c0;
  border-radius: 10px;
  padding: 8px;
  margin-right: 10px;
  width: 100px;
  cursor: pointer;
}

.container-multiple-price .label {
  font-size: 13px;
  white-space: nowrap;
}

.container-multiple-price .price {
  font-weight: bold;
  font-size: 14px;
}

.container-multiple-price.active {
  border-color: #7bdaa3;
  background-color: #bdffd9;
}